// Tailwind imports
@tailwind base;
@tailwind components;
@tailwind utilities;

// Define Color Variable here if needed
:root {
  --toastify-color-info: #25c7e5;
  --toastify-color-success: #31bf60;
  --toastify-color-warning: #f6c74e;
  --toastify-color-error: #f81e1e;

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
}

@media (prefers-color-scheme: light) {
  :root {
    // --background: #fff;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    // --background: #f00;
  }
}

// [data-theme='dark'] {
//   --background: #000;
// }

// Custom Tailwind Classes can be added here
// Sass is generally recommended to be used for selections and nesting
// Do checkout latest selection features from Tailwind as well
// Planning to deprecate Sass soon
@layer utilities {
  .form-input {
    @apply my-2 w-full rounded-lg border border-base-300 p-2;
  }

  .form-input-short {
    @apply my-2 rounded-lg border border-base-300 p-2;
  }

  .form-input-error {
    @apply my-2 w-full rounded-lg border border-error p-2 text-error;
  }
  .form-input-short-error {
    @apply my-2 rounded-lg border border-error p-2 text-error;
  }

  .form-submit-btn {
    @apply rounded-lg bg-primary p-2;
  }

  .dashboardCards {
    @apply flex-1 cursor-pointer rounded-2xl p-4 text-center text-base-300 drop-shadow-md md:p-8;
    @apply custom-transition-a hover:scale-105;
  }

  .graphSideCard {
    @apply flex cursor-pointer flex-col justify-center rounded-xl py-6 text-center text-base-300 drop-shadow-md;
    @apply custom-transition-a hover:scale-105;

    background: white;

    &:hover {
      background: #f1f1f1;
    }
  }

  .card {
    @apply rounded-xl bg-base-200 p-4;
  }

  .profileImage {
    // @apply  px-4 py-2 my-2 ;
    // @apply object-cover;
    @apply avatar h-32 w-32 rounded-full border-4 border-secondary object-cover;
  }

  // https://stackoverflow.com/a/69410151/3556531
  // Nice scrollbars
  .scrollbar::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }

  .gradientBack {
    @apply bg-gradient-to-b from-primary to-secondary;
  }

  .customPulse {
    animation: pulse 4s cubic-bezier(0.8, 0, 0.6, 1) infinite;

    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
    }
  }

  .custom-transition-a {
    @apply transition-all duration-500 ease-in-out;
  }

  .custom-transition-b {
    @apply transition-all duration-100 ease-linear;
  }

  .custom-transition-img-container {
    @apply transition-all duration-500 ease-in-out;

    &:hover {
      img {
        @apply scale-110 transition-all duration-500 ease-in-out;
      }
    }
  }

  .event-row-hover {
    @apply relative;

    #row-tooltip {
      @apply hidden;
    }

    &:hover {
      #row-tooltip {
        @apply absolute right-0 top-0 z-50 inline;
      }
    }
  }

  .animate-bg-closed-marquee-ltr {
    // background-image: url('/assets/do-not-cross/dnc-event-closed.png'); // works with app but doesn't work with Storybook
    // background-image: url('/public/assets/do-not-cross/dnc-event-closed.png'); // doesn't work with app but works with Storybook
    background-image: url('../../public/assets/do-not-cross/dnc-event-closed.png');
    background-repeat: repeat-x;
    background-size: contain;
    animation: moveBackgroundLTR linear 60s infinite;
  }

  .animate-bg-closed-marquee-rtl {
    background-image: url('../../public/assets/do-not-cross/dnc-event-closed.png');
    background-repeat: repeat-x;
    background-size: contain;
    animation: moveBackgroundRTL linear 60s infinite;
  }

  .animate-bg-soldout-marquee-ltr {
    background-image: url('../../public/assets/do-not-cross/dnc-sold-out.png');
    background-repeat: repeat-x;
    background-size: contain;
    animation: moveBackgroundLTR linear 60s infinite;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .animate-bg-soldout-marquee-rtl {
    background-image: url('../../public/assets/do-not-cross/dnc-sold-out.png');
    background-repeat: repeat-x;
    background-size: contain;
    animation: moveBackgroundRTL linear 60s infinite;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .animate-bg-cancelled-marquee-ltr {
    background-image: url('../../public/assets/do-not-cross/dnc-cancelled.png');
    background-repeat: repeat-x;
    background-size: contain;
    animation: moveBackgroundLTR linear 60s infinite;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .animate-bg-cancelled-marquee-rtl {
    background-image: url('../../public/assets/do-not-cross/dnc-cancelled.png');
    background-repeat: repeat-x;
    background-size: contain;
    animation: moveBackgroundRTL linear 60s infinite;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  @keyframes moveBackgroundLTR {
    0% {
      background-position: 0% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  @keyframes moveBackgroundRTL {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0% 0;
    }
  }

  // TODO: WIP - Give Wavy feel to the DO NOT CROSS banner
  // @keyframes waves {
  //   to {
  //     background-position:
  //       1600px 130%,
  //       3150px 130%,
  //       5300px 130%;
  //   }
  // }

  // @keyframes wave {
  //   0% {
  //     transform: translateX(0) translateZ(0) scaleY(1);
  //   }
  //   50% {
  //     transform: translateX(-25%) translateZ(0) scaleY(0.5);
  //   }
  //   100% {
  //     transform: translateX(-50%) translateZ(0) scaleY(1);
  //   }
  // }

  // WIP - https://www.hyperui.dev/blog/animated-border-gradient-with-tailwindcss
  // .animate-background {
  //   animation: background 10s ease-in-out infinite alternate;
  // }

  // @keyframes background {
  //   0% {
  //     background-position: 0% 50%;
  //   }
  //   50% {
  //     background-position: 100% 50%;
  //   }
  //   100% {
  //     background-position: 0% 50%;
  //   }
  // }

  // Classess for html elements coming from Notion's Database with standard classes.

  .notion-blog {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply mb-2 mt-6 font-semibold;
    }

    h1 {
      @apply text-4xl;
    }
    h2 {
      @apply text-3xl;
    }
    h3 {
      @apply text-2xl;
    }

    p {
      @apply mb-4 text-base-content;
    }

    a {
      @apply text-secondary underline underline-offset-2;
    }
  }

  //animation for scanner
  @keyframes translateTopToBottom {
    0% {
      height: 56px;
      opacity: 0;
    }
    50% {
      height: 180px;
      opacity: 0.5;
    }
    100% {
      height: 288px;
      opacity: 1;
    }
  }

  .animate-scanner {
    animation: translateTopToBottom 500ms linear;
  }

  .iconClass{
    @apply text-primary/70 w-4 h-4 md:w-6 md:h-6;
    @apply custom-transition-b hover:text-primary;
  }
}
